import axios from 'axios';
import { refreshToken } from './servicos/AuthService.js';

const instance = axios.create({
    baseURL: 'https://api.blueb2b.net',
});

instance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await refreshToken();
            if (newToken) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                return axios(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export default instance;