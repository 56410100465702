import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

async function refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    try {
        const response = await axios.post(`${apiUrl}/refresh-token`, { refreshToken });
        localStorage.setItem('token', response.data.token);
        return response.data.token;
    } catch (err) {
        console.error('Erro ao atualizar o token:', err);
        // Lógica de logout ou redirecionar para a página de login
    }
}

export { refreshToken };