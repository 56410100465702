import styled from "styled-components";

const LabelTxt = styled.label`
    color: black;
    text-align: left;
    align-self: center;
    font-weight: 600;
    color: gray;
`

export default LabelTxt;