import styled from "styled-components";
import Pesquisa from '../components/Pesquisa/index.js';
import TableSection from '../components/TableSection/index.js';
import LateJobs from "../components/LateJobs/index.js";

const AppContainer = styled.div`
    width: 100%;
`

function Home() {
  return (
    <AppContainer>
      <Pesquisa />
      <LateJobs />
      <TableSection />
    </AppContainer>
  );
}

export default Home;
