import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderUl = styled.ul` 
  display: flex;
  width: 50vw;
  justify-content: center;
  margin: 0;
  height: 3.2rem;
`

const HeaderList = styled.li`
  font-size: 1.2rem;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0vw 5vw;
`

const menuItens = ["TAREFAS","EQUIPE","PROJETOS"];

function NavHeader(){
    return(
        <HeaderUl>
        {menuItens.map((texto) => (          
          <HeaderList><Link to={"/" + texto.toLowerCase()}><p>{texto}</p></Link></HeaderList>
        ))}
      </HeaderUl>
    )
}

export default NavHeader;