import styled from "styled-components";
import Play from "../../images/play.svg";
import X from "../../images/x.svg"
import Edit from "../../images/edit.svg"
import Certo from "../../images/certo.svg"
import { concludeTask, cancelTask, editDate } from "../../servicos/jobs.js";
import { useEffect, useRef, useState } from "react";
import SubmitButton from "../SubmitButton/index.js";
import { convertsToTime, convertTimeToSeconds, updateTime } from "../../servicos/time.js";

const CardContainer = styled.div`
    width: 15vw;
    background-color: white;
    margin: 1vw;
    padding: 4vw 2vw;
    border-radius: 2rem;
    justify-content: center;
    display: grid;
    text-align: center;
    justify-items: center;
`

const ImagemCard = styled.img`
    height: 7rem;
`

const TituloCard = styled.h3`
    margin: .3rem;

`

const SubtituloCard = styled.h4`
    margin: .3rem;

`

const DescCard = styled.p`
    margin: .3rem;

`

const IconBox = styled.div`
    width: fit-content;
`

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1rem;
    justify-content: space-evenly;
`

const IconSelf = styled.img`
    width: 3vw;
    cursor: pointer;
`

const ChgDate = styled.a`
    margin-top: 1rem;
    color: blue;
    cursor: pointer;
`

const IptDate = styled.input`
    margin-top: 1rem;
    color: blue;
    width: 100%;
    text-align: center;
    border-radius: 1rem;
    padding: .3rem;
`

function CardJob({ id, hour, titulo, subtitulo, imagem, descricao, onUpdate, requireToolbar }) {

    const [isEditing, setIsEditing] = useState(false);
    const [dateSelected, setdateSelected] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    const [formattedTime, setFormattedTime] = useState('');
    // const [seconds, setSeconds] = useState(0);
    const intervalRef = useRef(null);



    useEffect(() => {
        if (hour) {
            setFormattedTime(hour);
        }
    }, [hour]);

    // useEffect(() => {
    //     if (isRunning) {
    //         intervalRef.current = setInterval(() => {
    //             setFormattedTime(prevSeconds => {
    //                 const newSeconds = convertTimeToSeconds(prevSeconds) + 1;
    //                 return convertsToTime(newSeconds)
    //             });
    //         }, 1000);
    //     } else {
    //         clearInterval(intervalRef.current);            
    //         updateTime(id, formattedTime);
            
    //     }
    //     return () => clearInterval(intervalRef.current);
    // // eslint-disable-next-line
    // }, [isRunning]);

    const handlePlay = () => {
        if (!isRunning) {
            intervalRef.current = setInterval(() => {
                setFormattedTime(prevSeconds => {
                    const newSeconds = convertTimeToSeconds(prevSeconds) + 1;
                    return convertsToTime(newSeconds)
                });
            }, 1000);
        } else {
            clearInterval(intervalRef.current);            
            updateTime(id, formattedTime);
            
        }
        setIsRunning(prevRunning => !prevRunning);
    }

    const handleConclude = async () => {
        try {
            await concludeTask(id);
            window.location.reload();
        } catch (error) {
            console.error('Erro ao concluir a tarefa:', error);
        }
    };

    const handleCancel = async () => {
        try {
            await cancelTask(id);
            window.location.reload();
        } catch (error) {
            console.error('Erro ao cancelar a tarefa:', error);
        }
    };

    const handleChangeDate = (event) => {
        setdateSelected(event.target.value);
    };

    const handleDateEdit = async () => {
        try {
            await editDate(id, dateSelected);
            window.location.reload();
        } catch (error) {
            console.error('Erro ao alterar a data:', error);
        }
    }

    const handleClickCD = () => {
        setIsEditing(true);
    };

    return (
        <CardContainer>
            <ImagemCard src={imagem} alt="imagem" />
            <TituloCard>
                Id: #{id}
            </TituloCard>
            <TituloCard>
                {titulo}
            </TituloCard>
            <SubtituloCard>
                {subtitulo}
            </SubtituloCard>
            <SubtituloCard>
                {formattedTime}
            </SubtituloCard>
            <DescCard>
                {descricao}
            </DescCard>
            {requireToolbar ? (
                <IconContainer>
                    <IconBox>
                        <IconSelf src={Certo} alt="Concluir" onClick={handleConclude}></IconSelf>
                    </IconBox>
                    <IconBox>
                        <IconSelf src={X} alt="Remover" onClick={handleCancel}></IconSelf>
                    </IconBox>
                    <IconBox>
                        <IconSelf src={Edit} alt="Editar"></IconSelf>
                    </IconBox>
                    <IconBox>
                        <IconSelf src={Play} alt="Play" onClick={handlePlay}></IconSelf>
                    </IconBox>
                </IconContainer>
            ) : isEditing ? (
                <>
                    <IptDate type="date" onChange={handleChangeDate} autoFocus></IptDate>
                    <SubmitButton onClick={handleDateEdit}>Enviar</SubmitButton>
                </>
            ) : (
                <>
                    <ChgDate onClick={(event) => {
                        event.preventDefault();
                        handleClickCD();
                    }}>Alterar Data</ChgDate>
                    <IconContainer>
                        <IconBox>
                            <IconSelf src={Certo} alt="Concluir" onClick={handleConclude}></IconSelf>
                        </IconBox>
                        <IconBox>
                            <IconSelf src={X} alt="Remover" onClick={handleCancel}></IconSelf>
                        </IconBox>
                    </IconContainer>
                </>
            )}
        </CardContainer >
    );
}

export default CardJob;