import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './rotas/Home.js';
import Tarefas from './rotas/Tarefas.js';
import Login from './rotas/Login.js';
import Header from './components/Header/index.js';
import { createGlobalStyle } from 'styled-components';
import reportWebVitals from './reportWebVitals.js';
import { AuthProvider } from './servicos/AuthContext.js';
import ProtectedRoute from './servicos/ProtectedRoute.js';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li {
    list-style: none;
  }

  a {
    color: black;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
`;

function App() {
  const location = useLocation();
  const hideHeaderRoutes = ['/login'];

  return (
    <>
      {!hideHeaderRoutes.includes(location.pathname) && <Header />}
      <main>
        <Routes>
          <Route path="/tarefas" element={<ProtectedRoute><Tarefas /></ProtectedRoute>} />
          <Route path="/equipe" element={<p>Hello World!</p>} />
          <Route path="/projetos" element={<p>Hello World!</p>} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="*" element={<Login />} />
        </Routes>
      </main>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();