import styled from "styled-components";
import {TituloHead} from "./TableSectionComponents.js";
import CardJob from "../CardJob/index.js";
import { useEffect, useState } from "react";
import { getJobs } from "../../servicos/jobs.js";

const NovosLivrosContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #EBECEE;
    flex-wrap: wrap;
`



function TableSection() {

    const [allJobs, setallJobs] = useState([])

    useEffect(() => {
        fetchAll();
    }, [])

    async function fetchAll() {
        const myJobs = await getJobs();
        setallJobs(myJobs);
    }

    const handleJobUpdate = (id) => {
        setallJobs((prevJobs) => prevJobs.filter(job => job.id !== id))
    }

    return (
        <section>
            <TituloHead cor="#000000">Entregas próximas</TituloHead>
            <NovosLivrosContainer>
            {allJobs.map(job => {
                    // Define o caminho da imagem com base na propriedade `brand`
                    const imageSrc = require(`../../images/minilogo/${job.brand.toLowerCase()}.png`);
                    
                    return (
                        <CardJob
                            key={job.id} // Adiciona uma chave única
                            id={job.id}
                            titulo={job.title}
                            subtitulo={job.targeteddate}
                            imagem={imageSrc}
                            descricao={job.description}
                            hour = {job.hours}
                            onUpdate = {handleJobUpdate}
                            requireToolbar = {true}
                        />
                    );
                })}
            </NovosLivrosContainer>            
        </section>
    )
}

export default TableSection