import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();


export function AuthProvider({ children }) {
    const [auth, setAuth] = useState(() => {
        // Verifica se há um token salvo no localStorage
        const token = localStorage.getItem('token');
        return token ? { token } : null;
    });

    const login = (token) => {
        setAuth({ token });
        localStorage.setItem('token', token); // Salva o token no localStorage
    };

    const logout = () => {
        setAuth(null);
        localStorage.removeItem('token'); // Remove o token do localStorage
    };

    const isAuthenticated = () => !!auth;

    useEffect(() => {
        // Efeito para verificar autenticação no início
        const token = localStorage.getItem('token');
        if (token) {
            setAuth({ token });
        }
    }, []);

    return (
        <AuthContext.Provider value={{ auth, login, logout, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}