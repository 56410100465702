import styled from "styled-components";
import { useEffect, useState } from "react";
import { gitJobs } from "../../servicos/jobs.js";

const JobsSection = styled.section`
    background-color: #EBECEE;
    height: calc(100vh - 8vh);
`

const JobTable = styled.table`
    padding: .5rem;
    width: max-content;
`

const JobsThead = styled.thead`

`

const JobsTbody = styled.tbody`

`

const JobsTh = styled.th`
    border: 1px solid black;
    background-color: white;
`

const JobsTd = styled.td`
    text-align: center;
    border: 1px solid black;
    background-color: white;
    width: 20vw;
`

const TrThead = styled.tr`

`

const TrTbody = styled.tr`

`

const TableContainer = styled.div`
    height: calc(100vh - 8vh);
    max-height: calc(100vh - 8vh);
    max-width: 100vw;
    overflow: auto;
`


function JobsTable() {

    const [allJobs, setallJobs] = useState([])

    useEffect(() => {
        fetchAll();
    }, [])

    async function fetchAll() {
        const myJobs = await gitJobs();
        setallJobs(myJobs);
    }

    return (
        <JobsSection>
            <TableContainer>
                <JobTable>
                    <JobsThead>
                        <TrThead>
                            <JobsTh>id</JobsTh>
                            <JobsTh>Cliente</JobsTh>
                            <JobsTh>Título</JobsTh>
                            <JobsTh>Descrição</JobsTh>
                            <JobsTh>Status</JobsTh>
                            <JobsTh>Data Início</JobsTh>
                            <JobsTh>Entrega Prevista</JobsTh>
                            <JobsTh>Horas</JobsTh>
                        </TrThead>
                    </JobsThead>
                    <JobsTbody>
                        {allJobs.map((job => (
                            <TrTbody>
                                <JobsTd>
                                    {job.id}
                                </JobsTd>
                                <JobsTd>
                                    {job.brand}
                                </JobsTd>
                                <JobsTd>
                                    {job.title}
                                </JobsTd>
                                <JobsTd>
                                    {job.description}
                                </JobsTd>
                                <JobsTd>
                                    {job.status}
                                </JobsTd>
                                <JobsTd>
                                    {job.startdate}
                                </JobsTd>
                                <JobsTd>
                                    {job.targetdate}
                                </JobsTd>
                                <JobsTd>
                                    {job.hours}
                                </JobsTd>
                            </TrTbody>
                        )))}
                    </JobsTbody>
                </JobTable>
            </TableContainer>
        </JobsSection>
    )
}

export default JobsTable;
