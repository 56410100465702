import profile from "../../images/perfil.svg";
import styled from "styled-components";
import { getUser } from "../../servicos/login.js";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Icone = styled.li`
    height: 100%;
`

const ProfileUl = styled.ul`
    margin: 0;
    height: 3.2rem;    
`

const Perfil = styled.a`
    display: flex;
    margin-right: 0;
    height: 100%;
    justify-items: center;
    align-items: center;
    position: relative;    
    cursor: pointer;
`

const PerfilPara = styled.p`
    font-size: 1.2rem;
    min-width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 0rem 1rem;
`

const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    text-align: center;
    display: grid;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
`

const DropdownItem = styled.a`
    border-top: 1px solid black;
    padding: .5rem;
`

function ProfileIcon() {
    const navigate = useNavigate(); // Cria uma instância do hook useNavigate
    const [user, setUser] = useState([])
    const [backOpen, setbackOpen] = useState(false)
    const token = localStorage.getItem('token');

    const toggleDropdown = () => {
        setbackOpen(!backOpen);
    };

    const handleLogout = () => {
        // Limpe qualquer informação de sessão ou token
        localStorage.removeItem('token'); // Remove o token do localStorage (ou use qualquer outra lógica de limpeza)
        localStorage.removeItem('refreshToken'); // Remove o refreshToken do localStorage

        // Redirecione o usuário para a página de login
        navigate('/login'); // Altere '/login' para a rota da sua página de login
    };

    const fetchAll = useCallback(async () => {
        const myUsers = await getUser(token);
        setUser(myUsers);
    }, [token]);

    useEffect(() => {
        fetchAll();
    }, [fetchAll]); // Usa fetchAll como dependência

    return (
        <>
            <ProfileUl>
                <Icone>
                    <Perfil onClick={toggleDropdown}>
                        <img className="profileIcon" src={profile} alt="profile"></img>
                        <PerfilPara>{user.name}</PerfilPara>
                        {backOpen && (
                            <DropdownMenu>
                                <DropdownItem>Perfil</DropdownItem>
                                <DropdownItem onClick={handleLogout}>Sair</DropdownItem>
                            </DropdownMenu>
                        )}
                    </Perfil>
                </Icone>
            </ProfileUl>
        </>
    );
}

export default ProfileIcon;