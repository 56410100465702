import styled from "styled-components";
import React, { useState } from "react";
import { authenticateLogin } from "../servicos/login.js";
import Logo from "../components/logo/index.js";
import { useNavigate } from "react-router-dom";

const BackgroundLogin = styled.div`
    margin: 0;
    height: 100vh;
    top: 0;
    position: absolute;
    width: 100vw;
    background-image: linear-gradient(90deg, #002F52 35%, #326589 165%);
    align-content:center;
    text-align: center;
`

const LoginBox = styled.div`
    background-color: white;
    border-radius : 2vh;
    padding: 10vh;
    text-align: center;
    align-content: center;
    display: inline-block
`

const ContainerInput = styled.div`
    margin: 2rem;
`

const ButtonSend = styled.button`
    margin: 2rem;
    padding: .5rem;
    border-radius: .5rem;
    background-color: blue;
    color: white;
    font-weight: 600;
`

const LoginTitle = styled.h2`

`

const MessageBox = styled.p`
    color: red;
    font-weight: 600;
    background: darkred;
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    margin: 1rem auto;
`


function Login() {

    const navigate = useNavigate();

    async function handleLogin(event) {
        event.preventDefault();
        try {
            const response = await authenticateLogin(email, password);
            localStorage.setItem('token', response.token);
            localStorage.setItem('refreshToken', response.refreshToken);
            setError('');
            navigate('/');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('Erro desconhecido. Tente novamente.');
            }
        }
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    return (
        <BackgroundLogin>
            {error && <MessageBox>{error}</MessageBox>}
            <LoginBox>
                <Logo />
                <LoginTitle>Login</LoginTitle>
                <form onSubmit={handleLogin}>
                    <ContainerInput>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </ContainerInput>
                    <ContainerInput>
                        <label>Senha:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </ContainerInput>
                    <ButtonSend type="submit">Entrar</ButtonSend>
                </form>
            </LoginBox>
        </BackgroundLogin>
    );
};

export default Login