import styled from "styled-components";
import JobsTable from "../components/JobsTable/index.js";

const AppContainer = styled.div`
    width: 100%;
    height: calc(100vh - 8vh);
`
function Tarefas() {
  return (
    <AppContainer>
      <JobsTable />
    </AppContainer>
  );
}

export default Tarefas;
