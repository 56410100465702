import axios from '../axiosConfig.js'; // Supondo que axiosConfig.js cria e exporta uma instância do axios

const apiUrl = process.env.REACT_APP_API_URL;

// Cria uma instância do axios para login
const LoginAPI = axios.create({ baseURL: apiUrl });

async function authenticateLogin(email, password) {
    try {
        const response = await LoginAPI.post('/login', { email, password });
        console.log('Login bem-sucedido:', response.data);
        return response.data;
    } catch (error) {
        console.error('Erro no login:', error);
        throw error; // Lança o erro para ser tratado no componente
    }
}

async function getUser(token) {
    try {
        const response = await axios.get(`${apiUrl}/users`, {
            headers: {
                'Authorization': `Bearer ${token}` // Enviando o token no cabeçalho Authorization
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao obter usuário:', error);
        throw error; // Lança o erro para ser tratado no componente
    }
}

export {
    authenticateLogin,
    getUser
};