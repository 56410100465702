import logo from "../../images/LOGOTIPO.svg";
import styled from "styled-components";

const LogoContainer = styled.div`
    display: flex;
    font-size: 1.3rem;
    align-items: center;
    height: 3.2rem;
    width: fit-content;
    margin: auto;
`

const LogoType = styled.strong`
    color:blue;
`

const LogoImg = styled.img`
    margin-right: .5rem;
    width: 3vw;
`

function Logo() {
    return (
        <LogoContainer>
            <LogoImg src={logo} alt="logo" />
            <p><LogoType>Blue</LogoType>Works</p>
        </LogoContainer>
    );
}

export default Logo;