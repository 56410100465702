import styled from "styled-components";

const Button = styled.button`
    border: 1px solid #FFF;
    background: transparent;
    border: 1px solid #FFF;
    padding: 2rem;
    border-radius: 50px;
    color: #FFF;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
`

export default Button;