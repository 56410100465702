import styled from "styled-components";

export const TituloHead = styled.h2`
    width: 100%;
    padding: 15px 0;
    background-color: #FFF;
    color: ${ props => props.cor || "#000000"};
    text-align: center;
    margin: 0;
    font-size: 2rem;
`