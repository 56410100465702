import axios from 'axios';

// Obtém o token do localStorage
const token = localStorage.getItem('token');

// Obtém a URL base da variável de ambiente
const apiUrl = process.env.REACT_APP_API_URL;

// Cria instâncias do axios com a URL base
const usersAPI = axios.create({ 
  baseURL: `${apiUrl}/users`, 
  headers: { 'Authorization': `Bearer ${token}` } 
});

async function getUsers() {
    const response = await usersAPI.get("/");
    return response.data;
}

const jobsApi = axios.create({ 
  baseURL: `${apiUrl}/getJobs`, 
  headers: { 'Authorization': `Bearer ${token}` } 
});

async function getJobs() {
    const response = await jobsApi.get("/");
    return response.data;
}

const allJobsApi = axios.create({ 
  baseURL: `${apiUrl}/gitJobs`, 
  headers: { 'Authorization': `Bearer ${token}` } 
});

async function gitJobs() {
    const response = await allJobsApi.get("/");
    return response.data;
}

const lateJobsApi = axios.create({ 
  baseURL: `${apiUrl}/getLateJobs`, 
  headers: { 'Authorization': `Bearer ${token}` } 
});

async function getLateJobs() {
    const response = await lateJobsApi.get("/");
    return response.data;
}

async function insertTask(formData) {
    console.log(formData);
    try {
        const response = await axios.post(`${apiUrl}/insertJobs`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao inserir jobs:', error);
        throw error;
    }
}

async function concludeTask(id) {
    try {
        const response = await axios.put(`${apiUrl}/concludeTask/${id}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao concluir task:', error);
        throw error;
    }
}

async function cancelTask(id) {
    try {
        const response = await axios.put(`${apiUrl}/cancelTask/${id}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao cancelar task:', error);
        throw error;
    }
}

async function editDate(id, date) {
    try {
        const response = await axios.put(`${apiUrl}/editDate/${id}/${date}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao editar data:', error);
        throw error;
    }
}

export {
    getUsers,
    getJobs,
    insertTask,
    concludeTask,
    cancelTask,
    getLateJobs,
    editDate,
    gitJobs
}