import Logo from "../logo/index.js";
import Profileicon from "../ProfileIcon/index.js";
import NavHeader from "../NavHeader/index.js";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderContainer = styled.header`
    background-color: #FFF;
    display: flex;
    justify-content: center;
    height: 8vh;
    align-items: center;
`

function Header() {
    return (
        <HeaderContainer>
            <Link to="/">
            <Logo />
            </Link>
            <NavHeader />
            <Profileicon />
        </HeaderContainer>
    )
}

export default Header;